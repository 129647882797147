import React from "react";

const ChartLoader = () => (
    <div className="row">
        <div className="col text-center">
            'Chargement...'
        </div>
    </div>
)

export default ChartLoader